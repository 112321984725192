import React from 'react'
import { FormattedMessage } from 'react-intl'
import Camion from '../../assets/menu/icon_camion.svg'
import Coche from '../../assets/menu/icon_coche.svg'
import Moto from '../../assets/menu/icon_moto.svg'
import { IHistory } from '../../pages/historico'
import { getShortCompany } from '../../utils/getCompany'
import route from '../../utils/route'
import ButtonLink from '../buttons/ButtonLink'
import styles from './index.module.scss'

interface Props {
  history: IHistory[]
}

const icons = {
  turismo: <Coche />,
  moto: <Moto />,
  camion: <Camion />,
}

export default function HistoricoTimeline({ history }: Props) {
  if (history && history.length) {
    return (
      <div className={styles.timeline}>
        {history.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <div key={index} className={`${styles.container} ${styles.left}`}>
                <div className={styles.content_left}>
                  <span className={styles.date}>{item.formattedDate}</span>
                  <span className={styles.text}>
                    {getShortCompany()} {item.tienda}
                  </span>
                  <div className={styles.matricula_item}>
                    {item.tipo_vehiculo &&
                      icons[item.tipo_vehiculo.toLowerCase()]}
                    <span className={styles.text}>{item.matricula}</span>
                  </div>
                  <span className={styles.text}>{item.operacion}</span>
                  <div className={styles.yellowdot_left} />
                </div>
              </div>
            )
          }
          if (index % 2 !== 0) {
            return (
              <div
                key={index}
                className={`${styles.container} ${styles.right}`}>
                <div className={styles.content_right}>
                  <span className={styles.date}>{item.formattedDate}</span>
                  <span className={styles.text}>
                    {getShortCompany()} {item.tienda}
                  </span>
                  <div className={styles.matricula_item}>
                    {item.tipo_vehiculo &&
                      icons[item.tipo_vehiculo.toLowerCase()]}
                    <span className={styles.text}>{item.matricula}</span>
                  </div>
                  <span className={styles.text}>{item.operacion}</span>
                  <div className={styles.yellowdot_right} />
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }
  return (
    <div className={styles.no_activity}>
      <p>
        <FormattedMessage id="historico.no_actividad" />
        <ButtonLink to={route('area-privada.index')}>
          <FormattedMessage id="volver_atras" />
        </ButtonLink>
      </p>
    </div>
  )
}
