import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect, useReducer } from 'react'
import useForm from 'react-hook-form'
import * as api from '../../api/user'
import { findClientHistory } from '../../api/user'
import CitaIcon from '../../assets/images/area_privada/icono_cita.svg'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import SelectorFooterDesktop from '../../components/FunnelSelector/Footers/SelectorFooterDesktop'
import HistoricoTimeline from '../../components/historicoTimeline/HistoricoTimeline'
import Layout from '../../components/Layout'
import AreaPrivadaLayout from '../../components/layouts/areaPrivadaLayout/AreaPrivadaLayout'
import RegisterStepTwo from '../../components/registerForm/fullRegister/step2/RegisterStepTwo'
import { SeoData } from '../../types/Seo'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import { startCitaPrevia } from '../../context/actions/citaPreviaActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import route from '../../utils/route'
import styles from './index.module.scss'
import { storeUserData, transformData } from '../../context/actions/userActions'
import {
  RegisterDocumentData,
  RegisterDocumentSchema,
} from '../../helpers/schemas/RegisterDocumentSchema'

export interface IHistory {
  matricula: string
  fecha: string
  operacion: string
  tienda: string
  tipo_vehiculo: string
  formattedDate: string
}
interface InitialState {
  step: number
  history: IHistory | null
  error: null
  loading: false
  success: boolean
}

const initialState: InitialState = {
  step: 0,
  history: null,
  error: null,
  loading: false,
  success: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_STEP':
      return {
        ...state,
        step: action.payload.step,
      }
    case 'GET_HISTORY':
      return {
        ...state,
        loading: true,
        success: false,
      }
    case 'GET_HISTORY_OK':
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        history: action.payload.history,
      }
    case 'GET_HISTORY_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        success: false,
        history: null,
      }
    case 'RESET_SUCCESS':
      return {
        ...state,
        success: false,
        loading: false,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'SUBMIT_DNI_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    default:
      throw new Error()
  }
}

function getClientHistory(idUser: string, dispatch) {
  dispatch({
    type: 'GET_HISTORY',
  })

  findClientHistory(idUser)
    .then((res) => {
      dispatch({
        type: 'GET_HISTORY_OK',
        payload: {
          history: res.history,
        },
      })
    })
    .catch(() => {
      dispatch({
        type: 'GET_HISTORY_FAILED',
        payload: {
          error: t('historico.error_info_user'),
        },
      })
    })
}

const Content: React.FC = () => {
  const dniForm = useForm<RegisterDocumentData>({
    validationSchema: RegisterDocumentSchema,
  })
  const [state, dispatch] = useReducer(reducer, initialState)
  const { userInfo, dispatch: ContextDispatch } = useContext(AppContext)

  useEffect(() => {
    if (userInfo?.userData?.usuario_nav) {
      getClientHistory(userInfo.idUser, dispatch)
    } else {
      dispatch({
        type: 'CHANGE_STEP',
        payload: { step: 1 },
      })
    }
  }, [])

  const onSubmitDni = (data: RegisterDocumentData) => {
    const {
      document: {
        documentValue: dniInput,
        documentCountry: dniCountry,
        documentType: dniType,
      } = {},
      dniRadio,
    } = data
    const { idUser } = userInfo
    if (dniRadio === 'no') {
      dispatch({
        type: 'CHANGE_STEP',
        payload: { step: 2 },
      })
      return null
    }
    dispatch({ type: 'SET_LOADING' })
    api
      .findClientByNIF(dniInput, dniType, dniCountry, idUser)
      .then((res) => {
        ContextDispatch(storeUserData(transformData(res)))
        getClientHistory(userInfo.idUser, dispatch)
      })
      .catch((err) => {
        if (err.message === 'DNI_USED') {
          return dispatch({
            type: 'SUBMIT_DNI_ERROR',
            payload: {
              error: t('registerForm.error_dni_existe'),
            },
          })
        }
        dispatch({
          type: 'SUBMIT_DNI_ERROR',
          payload: {
            error: t('historico.generic_error'),
          },
        })
      })
  }

  if (state.success) {
    dispatch({
      type: 'RESET_SUCCESS',
    })
    dispatch({
      type: 'CHANGE_STEP',
      payload: { step: 3 },
    })
  }

  const onGoToCitaPrevia = (e) => {
    e.preventDefault()
    const url = document.location.href
    ContextDispatch(startCitaPrevia(url))
    return navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
  }

  return (
    <div className={styles.main_content}>
      {state.step === 0 && <SpinnerWithText text={t('historico.loading')} />}
      {state.step === 1 && (
        <form onSubmit={dniForm.handleSubmit(onSubmitDni)}>
          {!state.loading && (
            <RegisterStepTwo globalError={state.error} form={dniForm} />
          )}
          {state.loading && (
            <SpinnerWithText
              loading={state.loading}
              text={t('historico.loading')}
            />
          )}
          <SelectorFooterDesktop formState={dniForm.formState} continueButton />
        </form>
      )}
      {state.step === 2 && (
        <div className={styles.content_wrapper}>
          <div className={styles.content_text}>
            <div className={styles.welcome}>
              <p>{t('historico.no_historia_1')}</p>
            </div>
            <p>{t('historico.no_historia_2')} </p>
            <p>{t('historico.no_historia_3')}</p>
          </div>
          <a
            href="#"
            onClick={onGoToCitaPrevia}
            className={styles.link_cita_previa}>
            <CitaIcon className={styles.cita_icon} />
            {t('historico.cita')}
          </a>
        </div>
      )}
      {state.step === 3 && !state.error && (
        <HistoricoTimeline history={state.history} />
      )}
      {state.step === 3 && state.error && <p>{state.error}</p>}
    </div>
  )
}
interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Historico({ data }: Props) {
  return (
    <Layout
      requireLogin={true}
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        { display: t('bread_crumb.historico') },
      ]}>
      <AreaPrivadaLayout title={t('historico.title')}>
        <Content />
      </AreaPrivadaLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query historicoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
